import React from "react";
import ProfileSideBar from "../../../components/profileSideBar/profileSideBar";
import { Link } from "react-router-dom";
import cardImg1 from "../../../context/cardImg1.png";
import profileImg1 from "../../../context/profileImg.png";
import Discount from "../../../components/discount/Discount";
import "./likedCourses.scss";

const LikedCourses = () => {
  const courses = [
    {
      id: 1,
      img: cardImg1,
      category: "Kasanachilik",
      title: "An’anaviy taqinchoqlar yasash",
      description:
        "An'anaviy usulda tayyorlangan bilaguzuk, juda chiroyli va naqshli",
      details: { users: 123, duration: "2s. 32d.", lessons: 25, rating: 4.9 },
      author: "Otabek Rajabov",
      profileImg: profileImg1,
      oldPrice: 256000,
      newPrice: 200000,
      paid: "free",
      date: "11.11.2024",
      rating: 4.9,
      viewsCount: 123456,
      share: 32,
      lessons: [
        {
          id: 1,
          title: "Kirish darsi",
          videoUrl: "https://www.w3schools.com/html/movie.mp4",
          description: "Bu darsda dasturlash asoslari bilan tanishamiz.",
        },
        {
          id: 2,
          title: "O'zgaruvchilar va ma'lumot turlari",
          videoUrl: "https://www.w3schools.com/html/mov_bbb.mp4",
          description:
            "O'zgaruvchilar va ma'lumot turlari haqida batafsil ma'lumot.",
        },
        {
          id: 3,
          title: "Funksiyalar va algoritmlar",
          videoUrl: "https://example.com/lesson3.mp4",
          description: "Funksiyalar va algoritmlar haqida asosiy tushunchalar.",
        },
      ],
    },
  ];
  return (
    <div className="profile-container">
      <div className="to-back">
        <div className="backInner">
          <Link to="/">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.5 18.3334V10H12.5V18.3334M2.5 7.50002L10 1.66669L17.5 7.50002V16.6667C17.5 17.1087 17.3244 17.5326 17.0118 17.8452C16.6993 18.1578 16.2754 18.3334 15.8333 18.3334H4.16667C3.72464 18.3334 3.30072 18.1578 2.98816 17.8452C2.67559 17.5326 2.5 17.1087 2.5 16.6667V7.50002Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Link>
          <span>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 12L10 8L6 4"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <Link to="/profile/prof" className="desktop-back-link">
            Shaxsiy kabinet
          </Link>
          <Link to="/profile/menus" className="mobile-back-link">
            Shaxsiy kabinet
          </Link>
          <span>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 12L10 8L6 4"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <span>Yoqqan kurslar</span>
        </div>
      </div>
      <div className="profile-inner liked-courses">
        <div className="left">
          <ProfileSideBar />
        </div>
        <div className="right">
          <div className="liked-courses-cards">
            {courses.map((course, index) => (
              <Link to={`/courses/course/${course.id}`} key={index}>
                <div className="liked-course-card">
                  <div className="card-img">
                    <img src={course.img} alt={course.title} />
                  </div>

                  <p className="card-title">{course.title}</p>
                  <p className="card-description">{course.description}</p>
                  <div className="rat">
                    <div className="detail">
                      <span>{course.details.rating}</span>
                      <svg
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.0003 2.16675L12.5753 7.38341L18.3337 8.22508L14.167 12.2834L15.1503 18.0167L10.0003 15.3084L4.85033 18.0167L5.83366 12.2834L1.66699 8.22508L7.42533 7.38341L10.0003 2.16675Z"
                          fill="#FEC967"
                        />
                      </svg>
                    </div>
                    <Link to="#">{course.category}</Link>
                  </div>
                  <Discount product={course} />
                  <div className="line"></div>
                  <div className="about-card">
                    <div className="detail">
                      <svg
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.3337 14.5V13.1667C11.3337 12.4594 11.0527 11.7811 10.5526 11.281C10.0525 10.781 9.37424 10.5 8.66699 10.5H3.33366C2.62641 10.5 1.94814 10.781 1.44804 11.281C0.947944 11.7811 0.666992 12.4594 0.666992 13.1667V14.5M15.3337 14.5V13.1667C15.3332 12.5758 15.1366 12.0018 14.7746 11.5349C14.4126 11.0679 13.9057 10.7344 13.3337 10.5867M10.667 2.58667C11.2406 2.73353 11.749 3.06713 12.1121 3.53487C12.4752 4.00261 12.6722 4.57789 12.6722 5.17C12.6722 5.76211 12.4752 6.33739 12.1121 6.80513C11.749 7.27287 11.2406 7.60647 10.667 7.75333M8.66699 5.16667C8.66699 6.63943 7.47308 7.83333 6.00033 7.83333C4.52757 7.83333 3.33366 6.63943 3.33366 5.16667C3.33366 3.69391 4.52757 2.5 6.00033 2.5C7.47308 2.5 8.66699 3.69391 8.66699 5.16667Z"
                          stroke="#757575"
                          strokeWidth="1.2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span>{course.details.users}</span>
                    </div>
                    <div className="detail">
                      <svg
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.66667 4.49992V8.49992L11.3333 9.83325M15.3333 8.49992C15.3333 12.1818 12.3486 15.1666 8.66667 15.1666C4.98477 15.1666 2 12.1818 2 8.49992C2 4.81802 4.98477 1.83325 8.66667 1.83325C12.3486 1.83325 15.3333 4.81802 15.3333 8.49992Z"
                          stroke="#757575"
                          strokeWidth="1.2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span>{course.details.duration}</span>
                    </div>
                    <div className="detail">
                      <svg
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 2.5L14.3333 8.5L5 14.5V2.5Z"
                          stroke="#757575"
                          strokeWidth="1.2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span>{course.details.lessons}</span>
                    </div>
                  </div>
                  <div className="author">
                    <div className="author-img">
                      <img src={course.profileImg} alt={course.author} />
                    </div>
                    <p className="author-name">{course.author}</p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LikedCourses;
